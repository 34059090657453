define("flaneur/models/shipping-path/index", ["exports", "flaneur/config/environment", "query-string", "flaneur/enums/incoterm", "fetch"], function (_exports, _environment, _queryString, _incoterm, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ShippingPath = (_dec = Ember._tracked, (_class = class ShippingPath {
    constructor({
      source_country_code,
      destination_country_code,
      incoterm,
      is_domestic
    }) {
      _initializerDefineProperty(this, "incoterm", _descriptor, this);

      this.source_country_code = source_country_code;
      this.destination_country_code = destination_country_code;
      this.is_domestic = is_domestic;
      this.incoterm = incoterm !== null && incoterm !== void 0 ? incoterm : '';
    }

    get isDomestic() {
      return this.is_domestic;
    }
    /**
     * Incoterm domestic includes all domestic paths
     * and also all paths within Europe
     */


    get isIncotermDomestic() {
      return this.incoterm === _incoterm.default.DOMESTIC;
    }

    get isIncotermCrossBorder() {
      return [_incoterm.default.DDU, _incoterm.default.DDP].includes(this.incoterm);
    }

    static async find(query = {}) {
      const queryParams = _queryString.default.stringify(_objectSpread({
        variant: 'all'
      }, query));

      const url = `${_environment.default.inventoryURL}/v2/shipping_paths?${queryParams}`;
      const res = await (0, _fetch.default)(url, {
        method: 'GET',
        credentials: 'include',
        cache: false
      });

      if (!res.ok) {
        throw new Error('error finding shipping paths');
      }

      const {
        shipping_paths = []
      } = await res.json();
      return {
        shipping_paths: shipping_paths.map(shippingPath => new ShippingPath(shippingPath))
      };
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "incoterm", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ShippingPath;
});